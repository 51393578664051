/* global Stripe */
function Checkout(config, formSelector) {
  this.config = config;

  this.$form = formSelector;
  this.$stripe = null;
  this.$elements = null;
  this.$card = null;
  this.$errorContainer = null;

  this.isProcessing = false;

  this.init();
}

Checkout.prototype = {
  constructor: Checkout,
  init() {
    this.$stripe = Stripe(this.config.stripe.publishableKey);
    this.$elements = this.$stripe.elements(this.config.stripe.elements);
    this.$card = this.$elements.create("card", this.config.card.opts);
    this.$errorContainer = document.querySelector(this.config.errorContainer.el);

    this.$card.mount(this.config.card.el);

    this.$form.addEventListener("submit", this.handleSubmit.bind(this));

    this.$card.addEventListener("change", ({ error }) => this.showError(error));
  },
  showError(error) {
    if (error) {
      this.$errorContainer.innerHTML = error.message;
    } else {
      this.$errorContainer.innerHTML = "";
    }
  },
  getCardholderDetails() {
    return Object.entries(this.config.stripe.fields).reduce((result, [key, field]) => {
      let value;

      if (Array.isArray(field)) {
        value = field
          .map(f => this.getFieldValue(this.$form, f))
          .filter(Boolean)
          .join(" ");
      } else {
        value = this.getFieldValue(this.$form, field);
      }

      // eslint-disable-next-line no-param-reassign
      result[key] = value;

      return result;
    }, {});
  },
  getFieldValue(form, field) {
    return form[field] ? form[field].value : null;
  },
  addPaymentMethod(paymentMethodId) {
    const $input = document.createElement("input");

    $input.type = "hidden";

    $input.name = "paymentMethodId";

    $input.value = paymentMethodId;

    this.$form.appendChild($input);

    this.$form.submit();
  },
  handleSubmit(e) {
    e.preventDefault();

    if (this.isProcessing) {
      return false;
    }

    const { paymentData } = this.config.stripe;

    this.isProcessing = true;

    return this.$stripe
      .createPaymentMethod("card", this.$card, paymentData)
      .then(({ error, paymentMethod }) => {
        if (error) {
          this.showError(error);
          this.isProcessing = false;
        } else {
          this.addPaymentMethod(paymentMethod.id);
        }
      });
  }
};

export default function bindCheckout(config, selector) {
  return [...document.querySelectorAll(selector)].map(f => new Checkout(config, f));
}
