import Vue from "vue";

import Expandable from "./Expandable";

/* eslint-disable no-new */
const initAccordion = (wrapperEl, itemSelector) =>
  [...wrapperEl.querySelectorAll(itemSelector)].map(
    el =>
      new Vue({
        el,
        name: "AccordionItem",
        extends: Expandable
      })
  );

const init = (els, itemSelector) => [...els].map(el => initAccordion(el, itemSelector));

export default init;
