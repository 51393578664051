import svg4everybody from "svg4everybody";

import accordion from "@/modules/accordion/index";
import mobileNav from "@/modules/mobile-nav/index";
import bindSubmitOnchange from "@/modules/submit-onchange";
import bindCheckout from "@/modules/checkout";
import bindStateSelect from "@/modules/stateSelect";
import bindSaveScroll from "@/modules/saveScroll";
import bindSlideshows from "@/modules/slideshow/index";
import "@/modules/toggle";

import "@/components/main.scss";

const SFS = window.SFS || {};
// const $ = s => document.querySelector(s);
const $$ = s => document.querySelectorAll(s);

function init() {
  svg4everybody();

  const doc = document.documentElement;
  const { CONFIG } = SFS;

  doc.classList.remove("js-loading");
  doc.classList.add("js-loaded");

  SFS.accordions = accordion($$("[data-accordion]"), "[data-expandable]");

  if (CONFIG.NAV_ITEMS) {
    SFS.mobileNav = mobileNav(CONFIG.NAV_ITEMS, "[data-mobile-nav]");
  }

  if (CONFIG.CHECKOUT) {
    SFS.checkout = bindCheckout(CONFIG.CHECKOUT, "[data-checkout-form]");
  }

  if (CONFIG.STATE_SELECT) {
    SFS.stateSelects = bindStateSelect(CONFIG.STATE_SELECT);
  }
  if (CONFIG.SLIDESHOWS) {
    SFS.slideshows = bindSlideshows(CONFIG.SLIDESHOWS);
  }

  bindSubmitOnchange();

  bindSaveScroll("[data-save-scroll]");
}

document.addEventListener("DOMContentLoaded", init);
