import Vue from "vue";
import Slideshow from "./Slideshow";

export default function(slideshowConfigs) {
  return slideshowConfigs.map(
    ({ id, images }) =>
      new Vue({
        el: `#${id}`,
        name: "SlideshowWrapper",
        components: {
          Slideshow
        },
        data: {
          id,
          images
        },
        render(h) {
          return h(Slideshow, {
            props: {
              id,
              images
            }
          });
        }
      })
  );
}
