import ScrollSaver from "../lib/scrollSaver";

const scrollSaver = new ScrollSaver();

export default function(selector) {
  return [...document.querySelectorAll(selector)].map(f => {
    f.addEventListener("submit", () => scrollSaver.save());
    return f;
  });
}
