import designWidths from "../design/breakpoints.json";

/**
 * Query helpers
 */

const simpleQuery = (match, variable) => `screen and (${match}:${variable})`;

const simpleNumericQuery = (match, variable) => simpleQuery(match, `${variable}px`);

const minMaxWidthQuery = (min, max) => `screen and ((min-width:${min}px) and (max-width:${max}px))`;

/**
 * The beast - parse a set of arguments and create a media query string accordingly
 */
const mediaQueryStringify = (...args) => {
  let result;

  if (args.length === 1) {
    if (typeof args[0] === "number") {
      // simple mob first width query
      result = simpleNumericQuery("min-width", args[0]);
    } else {
      result = simpleQuery("min-width", args[0]);
    }
  }

  if (args.length === 2) {
    if (typeof args[0] === "number" && typeof args[1] === "number") {
      result = minMaxWidthQuery(args[0], args[1]);
    }
    if (typeof args[0] === "string" && typeof args[1] === "number") {
      result = simpleNumericQuery(args[0], args[1]);
    } else {
      result = simpleQuery(args[0], args[1]);
    }
  }

  return result;
};

// State
const queries = {
  // DPI
  "high resolution": mediaQueryStringify("min-resolution", "1.5dppx"),

  // Orientation
  landscape: mediaQueryStringify("orientation", "landscape"),
  portrait: mediaQueryStringify("orientation", "portrait")
};

let widths = {};

const addQuery = (name, query) => {
  queries[name] = query;
  return query;
};

const addWidth = (name, width) => {
  widths[name] = width;
  addQuery(name, mediaQueryStringify(width));
};

const init = config => {
  widths = config;
  Object.keys(config).forEach(bp => addWidth(bp, config[bp]));
};

init(designWidths);

export default {
  init,
  addQuery,
  addWidth,
  getQueries: () => queries,
  getWidths: () => widths,
  mediaQueryStringify
};
