<template>
  <div :id="id" class="m-slideshow">
    <div class="m-slideshow__inner">
      <div class="m-slideshow__image-container">
        <img class="m-slideshow__image" :src="currentImage.url" :alt="currentImage.alt" />
      </div>
      <div v-if="imageCount > 1" class="m-slideshow__controls">
        <div class="g" :style="{ marginLeft: 0, marginRight: 0 }">
          <a href="#" title="Previous slide" class="node" @click.prevent="goPrev">
            <span class="chevron chevron--left"></span>
          </a>
          <a href="#" title="Next slide" class="node" @click.prevent="goNext">
            <span class="chevron chevron--right"></span>
          </a>
        </div>
      </div>
    </div>
    <div class="g pad-top-half">
      <div class="g__cell g__cell-10 g__cell-8--medium g--align-right m-slideshow__figcaption">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <small v-html="currentImage.caption"></small>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: {
      required: true,
      type: String
    },
    images: {
      required: true,
      type: Array
    }
  },
  data() {
    return {
      currentIndex: 0
    };
  },
  computed: {
    imageCount() {
      return this.images.length;
    },
    currentImage() {
      return this.images[this.currentIndex];
    }
  },
  methods: {
    goNext() {
      this.currentIndex = this.currentIndex === this.imageCount - 1 ? 0 : this.currentIndex + 1;
    },
    goPrev() {
      this.currentIndex = this.currentIndex === 0 ? this.imageCount - 1 : this.currentIndex - 1;
    }
  }
};
</script>
