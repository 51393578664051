/* global $, $$ */
/* exported Bliss */
import "blissfuljs";
import enquire from "enquire.js";

import breakpoint from "@/lib/breakpoint";
import pluginify from "@/lib/helpers/pluginify";

const defaultDisplayMap = {};

const queries = breakpoint.getQueries();

function getDefaultDisplay(elem) {
  const doc = elem.ownerDocument;
  const { nodeName } = elem;
  const disp = defaultDisplayMap[nodeName];

  if (disp) {
    return disp;
  }

  const temp = doc.body.appendChild(doc.createElement(nodeName));
  let { display } = window.getComputedStyle(temp, null);

  temp.parentNode.removeChild(temp);

  if (display === "none") {
    display = "block";
  }
  defaultDisplayMap[nodeName] = display;

  return display;
}

const Toggle = function toggle(trigger, opts) {
  this.trigger = trigger;
  this.opts = opts;
  const targetIds = trigger.getAttribute("data-target")
    ? trigger.getAttribute("data-target")
    : trigger.getAttribute("href");

  this.targets = targetIds.split(",").map(id => $(id.trim()));
  this.group = trigger.getAttribute("data-toggle-group");
  this.breakpoint = null;
  this.breakpointInvert = false;
  this.isActive = true;

  this.init();
};

Toggle.prototype = {
  constructor: Toggle,
  init() {
    if (!this.targets) {
      console.log(`[toggle] no target found for selector ${this.targetIds.join(", ")}`);
      return;
    }

    if (this.getBreakpoint(this.trigger)) {
      this.bindResponsive();
    }
    if (this.isCheckbox(this.trigger) || this.isRadio(this.trigger)) {
      this.bindChange();
    } else {
      this.bindClick();
    }
  },
  activate() {
    this.trigger.setAttribute("aria-controls", this.targetIds[0]);

    this.targets.forEach(target => {
      if (this.getVisibility(target)) {
        this.show(target, false);
      } else {
        this.hide(target);
      }
    });

    this.isActive = true;
  },
  deactivate() {
    const { opts } = this;

    this.isActive = false;

    this.targets.forEach(target => {
      this.trigger.classList.remove(opts.triggerInClass);
      this.trigger.removeAttribute("aria-expanded");
      this.trigger.removeAttribute("aria-controls");

      target._.style({ display: null });

      target.classList.remove(opts.targetInClass);
      target.classList.remove(opts.targetOutClass);

      target.removeAttribute("aria-hidden");
    });
  },
  isCheckbox({ type }) {
    return type && type === "checkbox";
  },
  isRadio({ type }) {
    return type && type === "radio";
  },
  bindChange() {
    this.trigger.addEventListener("change", () => {
      if (this.isActive) {
        this.targets.forEach(target => this.toggle(target));
      }
    });
  },

  bindClick() {
    this.trigger.addEventListener("click", e => {
      e.preventDefault();

      if (this.isActive) {
        this.targets.forEach(target => this.toggle(target));
      }
    });
  },
  bindResponsive() {
    const that = this;

    function setup() {
      return that.breakpointInvert ? that.activate() : that.deactivate();
    }

    function match() {
      return that.breakpointInvert ? that.deactivate() : that.activate();
    }

    function unmatch() {
      return that.breakpointInvert ? that.activate() : that.deactivate();
    }

    enquire.register(this.breakpoint, {
      setup,
      match,
      unmatch
    });
  },
  toggle(target) {
    return this.getVisibility(target) ? this.hide(target) : this.show(target);
  },
  show(target, shouldScrollIntoView = false) {
    const current = this;
    const isVisible = this.isVisible === true;

    current.trigger.classList.add(current.opts.triggerInClass);
    current.trigger.setAttribute("aria-expanded", isVisible);

    target._.style({ display: getDefaultDisplay(target) });

    target.classList.add(current.opts.targetInClass);
    target.classList.remove(current.opts.targetOutClass);

    target.setAttribute("aria-hidden", !current.isVisible);

    if (current.group) {
      $$(`[data-toggle-group="${current.group}"]`).forEach(groupTrigger => {
        if (groupTrigger !== current.trigger) {
          groupTrigger._.toggle("hideIfActive");
        }
      }, current);
    }

    if (shouldScrollIntoView && typeof current.trigger.scrollIntoView === "function") {
      setTimeout(function waitForShow() {
        current.trigger.scrollIntoView();
      }, 50);
    }
  },
  hide(target) {
    const current = this;

    current.trigger.classList.remove(current.opts.triggerInClass);
    current.trigger.setAttribute("aria-expanded", current.isVisible);

    target._.style({ display: "none" });

    target.classList.add(current.opts.targetOutClass);
    target.classList.remove(current.opts.targetInClass);

    target.setAttribute("aria-hidden", !current.isVisible);
  },
  hideIfActive() {
    if (this.isActive) {
      this.hide();
    }
  },
  getInitialVisiblity(el) {
    const dataInitialDisplay = el.getAttribute("data-initial-display");

    if (dataInitialDisplay && dataInitialDisplay.length) {
      return dataInitialDisplay !== "none";
    }

    return this.getVisibility(el);
  },
  getVisibility(target) {
    return (
      window.getComputedStyle(target, null).display !== "none" ||
      target.classList.contains(this.opts.targetInClass)
    );
  },
  getBreakpoint(el) {
    let breakpointString = el.getAttribute("data-breakpoint");

    if (breakpointString) {
      if (breakpointString.indexOf(this.opts.bpInvertTrigger) === 0) {
        this.breakpointInvert = true;
        breakpointString = breakpointString.substring(this.opts.bpInvertTrigger.length);
      } else {
        this.breakpoint = breakpointString;
      }

      if (queries[breakpointString]) {
        this.breakpoint = queries[breakpointString];
        return true;
      }

      console.log("breakpoint with name ", breakpointString, " is not defined");
      return false;
    }

    return false;
  }
};

Toggle.prototype.defaults = {
  bpInvertTrigger: "below ",
  triggerInClass: "toggle-target-is--in",
  targetOutClass: "toggle-is--out",
  targetInClass: "toggle-is--in"
};

const plugin = pluginify(Toggle);

$.add("toggle", plugin);

$.ready().then(function findElements() {
  $$("[data-toggle]").forEach(function bind(el) {
    el._.toggle();
  });
});
