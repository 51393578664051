function ScrollSaver() {
  this.restore();
  this.clear();
}

ScrollSaver.prototype = {
  constructor: ScrollSaver,
  save() {
    const x =
      typeof window.pageXOffset !== "undefined"
        ? window.pageXOffset
        : (document.documentElement || document.body.parentNode || document.body).scrollLeft;

    const y =
      typeof window.pageYOffset !== "undefined"
        ? window.pageYOffset
        : (document.documentElement || document.body.parentNode || document.body).scrollTop;

    window.localStorage.setItem("scrollPosition", JSON.stringify({ x, y }));
  },
  restore() {
    const stored = this.fetch();

    if (!stored) {
      return;
    }

    const { x, y } = stored;

    if (x || y) {
      window.scrollTo(x, y);
    }
  },
  fetch() {
    const stored = window.localStorage.getItem("scrollPosition");

    if (stored) {
      return JSON.parse(stored);
    }
    return null;
  },
  clear() {
    window.localStorage.removeItem("scrollPosition");
  }
};

export default ScrollSaver;
