<script>
export default {
  data: () => ({
    isExpanded: false,
    id: Math.floor(Math.random() * 1000000000)
  }),
  computed: {
    /**
     * Title HTML attribute value for the mobile expand nav trigger
     * @return {String} title
     */
    triggerTitle() {
      return this.isExpanded ? "Hide section" : "Expand section";
    },
    /**
     * HTML classname applied to the mobile expand nav trigger
     * @return {Object}
     */
    triggerClass() {
      return {
        "is--open": this.isExpanded
      };
    },
    /**
     * HTML classname applied to the target / nav content
     * @return {Object}
     */
    targetClass() {
      return {
        "is--open": this.isExpanded
      };
    },
    chevronClass() {
      return this.isExpanded ? "chevron--up" : "chevron--down";
    }
  },
  methods: {
    /**
     * Toggle the expanded state
     */
    toggleExpanded() {
      return this.isExpanded ? this.contract() : this.expand();
    },
    /**
     * Expand the section nav
     */
    expand() {
      this.isExpanded = true;
    },
    /**
     * Contract the section nav
     */
    contract() {
      this.isExpanded = false;
    }
  }
};
</script>
