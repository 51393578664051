function StateSelect({ countryFieldSelector, stateFieldSelector }, values) {
  this.$countryField = document.querySelector(countryFieldSelector);
  this.$stateField = document.querySelector(stateFieldSelector);

  this.stateFieldName = this.$stateField.getAttribute("name");
  this.values = values;

  this.init();
}

StateSelect.prototype = {
  constructor: StateSelect,
  init() {
    this.handleCountryChange();
    this.$countryField.addEventListener("change", () => this.handleCountryChange());
  },
  handleCountryChange() {
    const countryId = parseInt(this.$countryField.value, 10);

    if (this.values[countryId]) {
      this.setStateOptions(countryId);
    } else {
      this.disableStateField();
    }
  },
  setStateOptions(countryId) {
    this.removeStateOptions();

    Object.keys(this.values[countryId]).forEach(id => {
      this.addStateOption(id, this.values[countryId][id]);
    });

    this.showStateField();
  },
  removeStateOptions() {
    while (this.$stateField.hasChildNodes()) {
      this.$stateField.removeChild(this.$stateField.firstChild);
    }
  },
  addStateOption(id, state) {
    const $option = document.createElement("option");

    $option.value = id;
    $option.innerText = state;
    this.$stateField.appendChild($option);
  },
  showStateField() {
    this.$stateField.setAttribute("name", this.stateFieldName);
    this.$stateField.removeAttribute("disabled");
  },
  disableStateField() {
    this.removeStateOptions();
    this.$stateField.setAttribute("name", "");
    this.$stateField.setAttribute("disabled", "disabled");
  }
};

export default function bindStateSelect({ values, controls }) {
  return controls.map(selectors => new StateSelect(selectors, values));
}
