<template>
  <div class="m-dropdown__parent">
    <div class="m-mobile-nav__item g">
      <a :href="item.url" class="mobile-nav__text g__cell g__cell-10" :class="activeClass">
        {{ item.title }}
      </a>
      <button
        v-if="hasDropdown"
        class="g--align-right m-mobile-nav__item-expand"
        :class="triggerClass"
        :title="triggerTitle"
        :aria-controls="id"
        @click="toggleExpanded"
      >
        <span class="chevron" :class="chevronClass"></span>
      </button>
    </div>
    <div v-if="hasDropdown" :id="id" class="m-dropdown" :class="targetClass">
      <a
        v-for="child in item.children"
        :key="child.url"
        :href="child.url"
        class="m-dropdown__item"
        >{{ child.title }}</a
      >
    </div>
  </div>
</template>
<script>
import Expandable from "../accordion/Expandable";

export default {
  extends: Expandable,
  props: {
    item: {
      required: true,
      type: Object
    }
  },
  computed: {
    activeClass() {
      return {
        "is--active": this.isActiveSection
      };
    },
    isActiveSection() {
      return this.segmentOne.length && this.item.url.includes(this.segmentOne);
    },
    segmentOne() {
      return document.location.pathname.split("/")[1];
    },
    hasDropdown() {
      return this.item.children || false;
    },
    chevronClass() {
      return this.isExpanded ? "chevron--up" : "chevron--down";
    }
  }
};
</script>
