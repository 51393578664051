<template>
  <div id="mobile-nav-content" class="pad-top-double m-mobile-nav">
    <div class="g island island--lightgrey">
      <nav class="m-mobile-nav">
        <mobile-nav-item v-for="(item, index) in items" :key="index" :item="item"></mobile-nav-item>
      </nav>
    </div>
  </div>
</template>
<script>
import MobileNavItem from "./MobileNavItem";

export default {
  components: {
    MobileNavItem
  },
  props: {
    items: {
      required: true,
      type: Array
    }
  }
};
</script>
