import ScrollSaver from "../lib/scrollSaver";

const scrollSaver = new ScrollSaver();

function bind(form) {
  form.addEventListener("change", e => {
    scrollSaver.save();
    e.currentTarget.submit();
  });
}

export default function bindSubmitOnchange() {
  [...document.querySelectorAll("[data-submit-onchange]")].forEach(form => bind(form));
}
