/* exported Bliss */
import "blissfuljs";

export default function pluginify(Constructor) {
  const handle = Constructor.name.toLowerCase();

  return function plugin(option, param) {
    const el = this;
    let opts = { ...Constructor.prototype.defaults };

    // don't call again if already initialised on this object
    let data = el._.data[handle];

    if (typeof option === "object" && option) {
      opts = { ...opts, ...option };
    }

    if (!data) {
      data = new Constructor(this, opts);

      // Store a reference so can check if already initialised if called again
      el._.data[handle] = data;
    }

    // allow the calling of plugin methods on an instance by name, eg: $item.carousel('show')
    if (typeof option === "string") {
      data[option](param);
    }
  };
}
