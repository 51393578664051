import Vue from "vue";

import MobileNav from "./MobileNav";

/* eslint-disable no-new */

const init = (items, targetSelector) => {
  const target = document.querySelector(targetSelector);

  if (target) {
    return new Vue({
      el: target,
      name: "MobileNavWrapper",
      components: {
        MobileNav
      },
      data: {
        items
      },
      template: '<mobile-nav :items="items"/>'
    });
  }
  return null;
};

export default init;
